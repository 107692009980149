import React, { useState } from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
//import SEO from '../../shared/components/seo';
import Layout from '../../organisms/layout/layout';
import QualifyContent from '../../organisms/qualify-content/qualify-content';

const Qualify = props => {

  const { isMobile } = useMobileMode();
  const dataProduct = props.pageContext.data;
  const califications = props.pageContext.califications;
  const filteredCountryInfo = props.pageContext.countryInfo.filter(country => country.node_locale === process.env.LOCALE);

  const [modalActive, setmodalActive] = useState(null);

  useMobileMode();
  const menuActive = 2;

  return (
    <>
      {/* <SEO
        title={metaTitle ? (title ? title.title : '') : metaTitle}
        description={
          metaDescription !== null ? metaDescription.metaDescription : ''
        }
        lang="ES"
      /> */}

      {isMobile ? (
        <QualifyContent
          isMobile={isMobile}
          dataProduct={dataProduct}
          califications={califications}
          countryInfo={filteredCountryInfo[0]}
        ></QualifyContent>
      ) : (
          <Layout menuActive={menuActive} footerActive={false}
            setmodalActive={setmodalActive}
            modalActive={modalActive}
            title={props.pageContext.data.metaTitle}
            slug={props.path}
          >
            <QualifyContent
              isMobile={isMobile}
              dataProduct={dataProduct}
              califications={califications}
              countryInfo={filteredCountryInfo[0]}
            ></QualifyContent>
          </Layout>
        )}
    </>
  );
};

export default Qualify;
