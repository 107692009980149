import React, { useEffect } from 'react';

import Products from '../products/products';
import { pushGtagEvent } from '../../../shared/utils/metricManagementUtil';

import './relacted-product.scss';

const RelactedProducts = props => {
  const { title, relacted, dataProducts = [] } = props;
  const arrayRelacted = relacted !== null ? relacted : [];

  const gTagViewItemList = (products) => {
    const items = products.map( (product, index) => {
        const {id, productName, category} = product
        return {
          item_id: id,
          item_name: productName?.productName||'',
          coupon: 'Ninguno',
          discount: '0',
          index: index,
          item_list_name: category?.title.title||'',
          item_list_id: category?.id||'',
          affiliation: '',
          item_brand: 'Familia',
          item_category: 'Productos',
          item_variant: '',
          price: '0',
          currency: 'COP',
          quantity: '0',
        }
      })
    
    pushGtagEvent({
      event: 'view_item_list',
      params: {
        ecommerce:{
          items: items,
        },
        item_list_name: title ? title : '',
        item_list_id: '',
        user_data: [],
        log_status: ''
      }
    })
  }

  useEffect( () => {
    gTagViewItemList(relacted)
  }, [])

  const RenderRelactedItem = props => {
    const { relacted, dataProducts } = props;
    return relacted.map((val, index) => {
      const dataProduct = dataProducts.filter(
        value => value.productId === val.contentful_id
      );

      const calification = dataProduct.length > 0 ? dataProduct[0].average : 0;

      return (
        <div key={index} className="f-product-relacted-item">
          <Products product={val} index={index} calification={calification}></Products>
        </div>
      );
    });
  };

  return (
    <div className="f-product-relacted">
      <h2 className="f-product-relacted-header">{title}</h2>
      <div className="f-product-relacted-content">
        <RenderRelactedItem
          relacted={arrayRelacted}
          dataProducts={dataProducts}
        ></RenderRelactedItem>
      </div>
    </div>
  );
};

export default RelactedProducts;
