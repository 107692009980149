import Fab from '@mui/material/Fab';
import React from 'react';
import RelactedProducts from '../../molecules/relacted-product/relacted-product';
import {removeDoubleSlash} from '../../../shared/utils/urlUtil'
import './relacted-qualify.scss';

const RelactedQualify = props => {

  const { dataProduct, dataProducts } = props;

  return (
    <div className="f-relacted-qualify">
      <RelactedProducts
        title={'Califica otro producto'}
        relacted={dataProduct.relatedProducts}
        dataProducts={dataProducts}
      ></RelactedProducts>
      <a href={removeDoubleSlash(`/productos/${dataProduct.category.slug}${dataProduct.slug}`)} className={"nostyle"}>
        <Fab
          variant="extended"
          size="large"
          aria-label="add"
          className="f-button-question-next"
        >
          REGRESAR
        </Fab>
      </a>
    </div>
  );
};

export default RelactedQualify;