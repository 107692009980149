import React, { useEffect } from 'react';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import './message-qualify.scss';

const MessageQualify = props => {

  const datalayerCalificacionEnviada = () => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Calificar_Producto',
      category: 'Productos',
      action: 'view',
      label: 'Tu calificación ha sido enviada ¡exitosamente!',
      item_name: props.productName,
      location: location,
      user_data: [],
      log_status: ''
    })
    pushDataLayerEvent({
      event: 'review',
      action_type: 'Submit a review',
      single_item_id: props.productId,
      single_item_name: props.productName,
    })
  }

  useEffect( () => {
    datalayerCalificacionEnviada()
  }, [])

  return (
    <div className="f-form-mensaje">
      <i className="fa fa-check-circle icon-succes"></i>
      <div className="f-mensaje-title">
        Tu calificación <br></br> ha sido enviada{' '}
        <span className="f-negrilla">¡exitosamente!</span>
      </div>
    </div>
  );
};

export default MessageQualify;
