import { TextField } from '@mui/material';
import React from 'react';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import './google-api-places.scss';

const MY_API_KEY = 'AIzaSyCN8ZrJwdNr5hJ4FkpgwMR6qzH1KS4TWlg';

export default class googleApiPlaces extends React.Component {

  state = {
    search: '',
    value: this.props.value,
  };

  constructor(props) {
    super(props);
  }

  handleInputChange = e => {
    this.setState({ search: e.target.value, value: e.target.value });
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({
      search: '',
      value: geocodedPrediction.formatted_address,
    });
    this.props.onSuggestionChange(
      originalPrediction.id,
      geocodedPrediction.formatted_address
    );
  };

  handleNoResult = () => {
    // console.log('No results for ', this.props.adress.search);
  };

  handleStatusUpdate = status => {
    // console.log(status);
  };

  render() {
    const { search, value } = this.state;
    const { children, styles, label, required, error = false } = this.props;

    return (
      <ReactGoogleMapLoader
        params={{
          key: MY_API_KEY,
          libraries: 'places,geocode',
        }}
        render={googleMaps =>
          googleMaps ? (
            <ReactGooglePlacesSuggest
              googleMaps={googleMaps}
              autocompletionRequest={{
                input: search,
              }}
              onNoResult={this.handleNoResult}
              onSelectSuggest={this.handleSelectSuggest}
              onStatusUpdate={this.handleStatusUpdate}
              textNoResults="No fue posible encontrar esta ciudad" // null or "" if you want to disable the no results item
              customRender={prediction => (
                <>
                  {prediction !== undefined &&
                    prediction.description.includes(process.env.COUNTRY_NAME) && (
                      <div className="f-customWrapper">
                        {prediction.description}
                      </div>
                    )}
                </>
              )}
            >
              <TextField
                classes={{
                  root: styles,
                }}
                type="text"
                label={label}
                value={value}
                required={required}
                error={error}
                helperText={error ? 'Debes seleccionar una opción' : ''}
                onChange={this.handleInputChange}
              />
            </ReactGooglePlacesSuggest>
          ) : (
              <TextField
                classes={{
                  root: styles,
                }}
                type="text"
                label={label}
                value={value}
                required={required}
                error={error}
                helperText={error ? 'Debes seleccionar una opción' : ''}
                onChange={this.handleInputChange}
              />
            )
        }
      />
    );
  }
}
