import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import MobileStepper from '@mui/material/MobileStepper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Rating from '@mui/lab/Rating';
import { navigate } from 'gatsby';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import RatingService from '../../../services/rating-service';
import GoogleSuggestion from '../../../shared/components/atoms/google-api-places/google-api-places';
import HeaderContentQualify from '../../molecules/header-content-qualify/header-content-qualify';
import MessageQualify from '../../molecules/message-qualify/message-qualify';
import RelactedQualify from '../../molecules/relacted-qualify/relacted-qualify';
import DataRatingQualify from './data-rating-qualify';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import './qualify-content.scss';

const QualifyContent = props => {

  const { isMobile, dataProduct, califications } = props;
  const [productRelacted, setProductRelacted] = React.useState(false);
  const [dataProducts, setDataProducts] = React.useState([]);
  const [successSaveForm, setSuccessSaveForm] = React.useState(false);
  const [progresBar, setProgresBar] = React.useState(0);
  const [presentationForm, setPresentationForm] = React.useState('');
  const [commentForm, setCommentForm] = React.useState();
  const [cityForm, setCityForm] = React.useState({ id: null, name: null });
  const [frmPruebasApruebas, setFrmPruebasApruebas] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");

  let urlImage = dataProduct.image != null ? dataProduct.image[0].file.url : null;
  let presentations = dataProduct.productPresentations !== null ? dataProduct.productPresentations : [];

  const dataSaveRating = DataRatingQualify(dataProduct);

  const redirectProducts = () => {
    navigate(`/productos/${dataProduct.category.slug}/${dataProduct.slug}`);
  };

  const objComentario = {
    title: 'Tu opinión es <span class="f-negrilla">importante</span> (opcional)',
  };

  const questionsDefault = [
    {
      title: `Seleciona la  <span class="f-negrilla">presentación del producto</span> a calificar*`,
      questionType: {
        title: 'Lista',
      },
    },
    {
      title:
        'Primero cuéntanos ¿en qué <span class="f-negrilla">ciudad vives</span>?*',
      questionType: {
        title: 'Lista',
      },
    },
  ];

  let questionsData = dataProduct.preguntas !== null ? questionsDefault.concat(dataProduct.preguntas) : questionsDefault;
  questionsData.push(objComentario);

  const [questions, setQuestions] = React.useState(questionsData);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = questions.length;

  const handleNext = (event, index) => {
    let enableNext = false;
    setValue(null);
    // Validaciones de los cambos de calificar
    if (presentationForm !== '' && activeStep === 0) {
      enableNext = true;
    }
    if (activeStep === 1 && (process.env.COUNTRY_CODE !== "12" ? city !== "" : state !== "")) {
      enableNext = true;
    }

    if (frmPruebasApruebas[activeStep] !== undefined && frmPruebasApruebas[activeStep] !== null && activeStep > 1) {
      enableNext = true;
    }

    if (enableNext) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setProgresBar(progresBar + 100 / (maxSteps - 1).toFixed(1));
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setProgresBar(progresBar - 100 / (maxSteps - 1).toFixed(1));
  };

  const handleSendForm = () => {
    dataSaveRating.comment = commentForm === undefined ? '' : commentForm.trim();
    let cityPerCountry = process.env.COUNTRY_CODE === "12" ? state : city;
    dataSaveRating.city = Number(cityPerCountry);
    // {
    //   id: cityForm.id,
    //   name: city.length > 0 ? city[0] : cityForm.name,
    //   createdAt: null,
    //   updatedAt: null,
    // };
    dataSaveRating.product.productPresentation.quantity = presentationForm;

    const arrayQualifications = [];
    const dataAskSave = frmPruebasApruebas.filter(Boolean);
    dataProduct.preguntas.map((val, index) => {
      let valueAsk = califications.filter(
        resp => parseInt(resp.numberValue) === dataAskSave[index]
      );

      // console.log(dataAskSave[index]);
      // console.log(valueAsk);

      valueAsk =
        valueAsk.length === 0
          ? califications.filter(resp => resp.title === dataAskSave[index])
          : valueAsk;
      let arrQualifications = [];
      if (typeof dataAskSave[index] === 'number') {
        arrQualifications = arrayQualifications.push({
          id: val.contentful_id,
          nameAsk: val.title,
          qualification: {
            id: valueAsk[0].contentful_id,
            numberValue:
              typeof dataAskSave[index] === 'number' ? dataAskSave[index] : 0,
            wordValue: valueAsk[0].title,
            createdAt: null,
            updatedAt: null,
          },
          questionCategory:
            typeof dataAskSave[index] === 'number'
              ? {
                id:
                  val.questionCategory != null
                    ? val.questionCategory.contentful_id
                    : null,
                name:
                  val.questionCategory != null
                    ? val.questionCategory.title
                    : null,
                description: null,
                createdAt: null,
                updatedAt: null,
              }
              : null,
        });
      } else {
        arrQualifications = arrayQualifications.push({
          id: val.contentful_id,
          nameAsk: val.title,
          qualification: {
            id: valueAsk[0].contentful_id,
            numberValue: 0,
            wordValue: valueAsk[0].title,
            createdAt: null,
            updatedAt: null,
          },
        });
      }
      return arrayQualifications;
    });
    dataSaveRating.listAsks = arrayQualifications;
    RatingService.saveRating(dataSaveRating).then(data => {
      setSuccessSaveForm(true);
      setTimeout(() => {
        setProductRelacted(true);
      }, 3000);
    });
  };

  const handleChangeComment = event => {
    setCommentForm(event.target.value);
  };

  const setDataQuestion = () => {
    setQuestions(questions);
  };

  const handleChangePresentation = event => {
    setPresentationForm(event.target.value);
  };

  const handleChangeCity = event => {
    setCityForm(event.target.value);
  };

  const handleChangeRating = (event, value, index) => {
    frmPruebasApruebas[index] = value;
    setValue(value);
    setFrmPruebasApruebas(frmPruebasApruebas);
  };

  React.useEffect(() => {
    setDataQuestion();
    RatingService.getProductAvg().then(data => {
      setDataProducts(data.data);
    });
  }, []);

  const datalayerCalificar = (categoryName, labelText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Calificar_Producto',
      category: categoryName,
      action: 'click',
      label: labelText,
      item_name: dataProduct.metaTitle,
      location: location,
      user_data: [],
      log_status: ''
    })
    pushDataLayerEvent({
      event: 'review',
      action_type: 'Submit a review',
      single_item_id: dataProduct.contentful_id,
      single_item_name: dataProduct.category.title?.title+ ' '+dataProduct.productName?.productName,
    })
  }

  return (
    <div className="f-product-qualify">
      <div className="f-product-qualify-header">
        <div className="f-product-qualify-header-title">
          <i className="fa fa-arrow-left" onClick={redirectProducts}></i>
          <label>Calificar</label>
        </div>
      </div>
      <div className="f-product-qualify-container">
        <div className={`f-product-qualify-content ${productRelacted ? 'f-product-qualify-relacted' : ''}`}>
          <div className={`f-product-qualify-logo ${productRelacted ? 'f-product-qualify-logo-center' : ''}`}>
            <img
              src="https://images.ctfassets.net/tcoigcjw85h2/6HwiWVp6Nrm6zr9lJXBPog/c7fdf8306af03061f15899fd9ee71838/logo_pruebas_y_apruebas.svg"
              alt="imagen calidad contenido"
            ></img>
          </div>
          {!productRelacted ? (
            <>
              <div className="f-product-qualify-header-container">
                {!successSaveForm || !isMobile ? (
                  <HeaderContentQualify
                    urlImage={urlImage}
                    dataProduct={dataProduct}
                  ></HeaderContentQualify>
                ) : (
                    <MessageQualify productName={dataProduct.category.title?.title+ ' '+dataProduct.productName?.productName} productId={dataProduct.contentful_id}></MessageQualify>
                  )}
              </div>
              <div className="f-product-qualify-content-container">
                {successSaveForm && !isMobile ? (
                  <MessageQualify productName={dataProduct.category.title?.title+ ' '+dataProduct.productName?.productName} productId={dataProduct.contentful_id}></MessageQualify>
                ) : null}
                {!successSaveForm ? (
                  <>
                    <LinearProgress
                      className="f-linear-progress"
                      variant="determinate"
                      value={progresBar}
                    />
                    <div className="f-product-qualify-content-form">
                      {activeStep > 1 && activeStep !== maxSteps - 1 ? (
                        <div className="f-questions-container">
                          <div className="f-icon-questions">
                            {questions[activeStep].icon != null ? (
                              <img
                                src={
                                  questions[activeStep].icon != null
                                    ? questions[activeStep].icon.file.url
                                    : null
                                }
                                alt="imagen calidad contenido"
                              ></img>
                            ) : null}
                          </div>
                          <div className="f-title-questions">
                            {questions[activeStep].title !== undefined
                              ? questions[activeStep].title
                              : null}
                            *
                          </div>
                        </div>
                      ) : (
                          <div className="f-title-questions-complete">
                            {questions[activeStep].title !== undefined
                              ? ReactHtmlParser(questions[activeStep].title)
                              : null}
                          </div>
                        )}
                      {activeStep === 0 ? (
                        <FormControl className="f-contact-selectfield">
                          <InputLabel>Presentación</InputLabel>
                          <Select
                            value={presentationForm}
                            onChange={handleChangePresentation}
                            variant="standard"
                          >
                            <MenuItem value="">
                              <em>SELECCIONAR</em>
                            </MenuItem>
                            {presentations.map((val, index) => (
                              <MenuItem
                                key={index}
                                value={val.quantity ? val.quantity : ''}
                              >
                                X{val.quantity ? val.quantity : ''}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : activeStep === 1 ? (
                        <div className="f-qualify-location">
                        <FormControl
                            className="f-contact-selectfield"
                        >
                            <InputLabel>
                              {props.countryInfo.nombreDepartamento}
                            </InputLabel>
                            <Select
                                value={state}
                                displayEmpty
                                onChange={event => { setState(event.target.value); setCity("") }}
                                variant="standard"
                            >
                                {props.countryInfo.departamentos.map(
                                    (state, index) => (
                                        <MenuItem key={index} value={state.codigo}>
                                            {state.nombre}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                        {process.env.COUNTRY_CODE === "12" ? null :
                            <FormControl
                                className="f-contact-selectfield"
                            >
                                <InputLabel htmlFor="outlined-state-simple">
                                    {props.countryInfo.nombreCiudad}
                                </InputLabel>
                                <Select
                                    value={city}
                                    displayEmpty
                                    onChange={event => { setCity(event.target.value); }}
                                    variant="standard"
                                >
                                    {props.countryInfo.ciudades.filter(city => city.codigoDepartamento === state).map(
                                        (city, index) => (
                                            <MenuItem key={index} value={city.codigo}>
                                                {city.nombre}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        }
                    </div>
                        // <GoogleSuggestion
                        //   styles="f-qualify-textfield"
                        //   label="Ciudad"
                        //   value={cityForm.name}
                        //   required={true}
                        //   error={false}
                        //   onSuggestionChange={(id, name) => {
                        //     setCityForm({ id, name });
                        //   }}
                        // ></GoogleSuggestion>
                      ) : activeStep === maxSteps - 1 ? (
                        <TextField
                          value={commentForm}
                          onChange={handleChangeComment}
                          className="f-comment-form"
                          multiline={true}
                          rows={4}
                          rowsMax={4}
                          placeholder="Comentario"
                        />
                      ) : (
                              <>
                                {questions[activeStep].questionType.title === 'Estrellas' ? (
                                  <Rating
                                    classes={{ root: 'f-rating-form' }}
                                    name="half-rating"
                                    value={parseInt(frmPruebasApruebas[activeStep])}
                                    onChange={(event, newValue) =>
                                      handleChangeRating(event, newValue, activeStep)
                                    }
                                  />
                                ) : (
                                    <div className="f-conditional-form">
                                      <Fab
                                        size="small"
                                        aria-label="add"
                                        className={`f-button-answer-conditional ${
                                          frmPruebasApruebas[activeStep] === 'Si'
                                            ? 'f-button-answer-conditional-check'
                                            : ''
                                          }`}
                                        onClick={event =>
                                          handleChangeRating(event, 'Si', activeStep)
                                        }
                                      >
                                        SI
                                </Fab>
                                      <Fab
                                        size="small"
                                        aria-label="add"
                                        className={`f-button-answer-conditional ${
                                          frmPruebasApruebas[activeStep] === 'No'
                                            ? 'f-button-answer-conditional-check'
                                            : ''
                                          }`}
                                        onClick={event =>
                                          handleChangeRating(event, 'No', activeStep)
                                        }
                                      >
                                        NO
                                </Fab>
                                    </div>
                                  )}
                              </>
                            )}

                      <MobileStepper
                        className="f-buttons-questions"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        backButton={
                          activeStep > 0 ? (
                            <Fab
                              variant="extended"
                              size="medium"
                              aria-label="add"
                              className="f-button-question-back"
                              onClick={ () => {
                                switch (activeStep) {
                                  case 1:
                                    datalayerCalificar('Ciudad donde vives', 'Atrás')
                                    break
                                  
                                  case maxSteps - 1:
                                    datalayerCalificar('Comentario producto', 'Atrás')
                                    break
                                  
                                  default:
                                    datalayerCalificar(questions[activeStep].title, 'Atrás')
                                    break
                                }
                                handleBack()
                              }}
                              disabled={activeStep === 0}
                            >
                              ATRÁS
                            </Fab>
                          ) : (
                              ''
                            )
                        }
                        nextButton={
                          <Fab
                            variant="extended"
                            size="medium"
                            aria-label="add"
                            className={`f-button-question-next ${
                              activeStep === maxSteps - 1
                                ? 'f-button-question-next-finish'
                                : activeStep === 0
                                  ? 'f-button-question-next-complete'
                                  : ''
                              }`}
                            onClick={ () => {
                              switch (activeStep) {
                                case 0:
                                  if(presentationForm) datalayerCalificar('Presentación del producto',`X${presentationForm}`)
                                  break

                                case 1:
                                  if(state && city) datalayerCalificar('Ciudad donde vives','Siguiente')
                                  break
                                
                                case maxSteps - 1:
                                  datalayerCalificar('Comentario producto','Finalizar')
                                  break
                                
                                default:
                                  if(frmPruebasApruebas[activeStep]) datalayerCalificar(questions[activeStep].title,frmPruebasApruebas[activeStep])
                                  break
                              }

                              activeStep === maxSteps - 1
                                ? handleSendForm()
                                : handleNext()
                              }
                            }
                          >
                            {activeStep !== maxSteps - 1
                              ? 'SIGUIENTE'
                              : 'FINALIZAR'}
                          </Fab>
                        }
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </>
          ) : (<RelactedQualify dataProduct={dataProduct} dataProducts={dataProducts}></RelactedQualify>)
          }
        </div>
      </div>
    </div>
  );
};

export default QualifyContent;