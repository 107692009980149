import React from 'react';
const DataRatingQualify = dataProduct => {
  return {
    product: {
      id: dataProduct.contentful_id,
      contentfulId: dataProduct.contentful_id,
      name: dataProduct.productName.productName,
      description:
        dataProduct.description !== null
          ? dataProduct.description.description
          : '',
      productCategory: {
        id: dataProduct.category.contentful_id,
        name: dataProduct.category.title.title,
        description: dataProduct.category.title.title,
        createdAt: '',
        updatedAt: '',
      },
      productPresentation: {
        id:
          dataProduct.productPresentations !== null
            ? dataProduct.productPresentations[0].contentful_id
            : null,
        name:
          dataProduct.productPresentations !== null
            ? dataProduct.productPresentations[0].title
            : null,
        quantity: null,
        measurementUnit: {
          id:
            dataProduct.productPresentations !== null
              ? dataProduct.productPresentations[0].measurementUnit !== null
                ? dataProduct.productPresentations[0].measurementUnit.contentful_id
                : null
              : null,
          name:
            dataProduct.productPresentations !== null
              ? dataProduct.productPresentations[0].measurementUnit !== null
                ? dataProduct.productPresentations[0].measurementUnit.title
                : null
              : null,
          shortName:
            dataProduct.productPresentations !== null
              ? dataProduct.productPresentations[0].measurementUnit !== null
                ? dataProduct.productPresentations[0].measurementUnit.shortName
                : null
              : null,
          description: null,
          createdAt: '',
          updatedAt: '',
        },
      },
      isNew: dataProduct.isNewProduct,
      isOffer: dataProduct.hasOffer,
      createdAt: '',
      updatedAt: '',
    },
  };
};

export default DataRatingQualify;
