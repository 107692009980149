import React from 'react';
import './header-content-qualify.scss';

const HeaderContentQualify = props => {
  const { dataProduct, urlImage } = props;

  return (
    <div className="f-product-qualify-content-header">
      <div className="f-product-qualify-content-img">
        <img src={urlImage} alt="imagen calidad contenido"></img>
      </div>
      <div className="f-product-qualify-content-text">
        <div className="f-product-qualify-content-category">
          {dataProduct.category.title.title}
        </div>
        <div className="f-product-qualify-content-title">
          {dataProduct.productName.productName}
        </div>
      </div>
    </div>
  );
};

export default HeaderContentQualify;
